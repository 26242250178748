import { Inject, Injectable } from '@angular/core';
import { Msg, PidTagBody, PidTagSubject } from 'msg-parser';
import PostalMime from 'postal-mime';
import { Observable, catchError, from, map, of, switchMap } from 'rxjs';
import { ApiService } from './api.service';
import { LibraryService } from './library.service';
import { FunctionsService, LIBRARY_SERVICE_TOKEN } from 'processdelight-angular-components';

export enum PreviewType {
  Video,
  Image,
  Audio,
  Iframe,
  Email,
  Error,
}

@Injectable({
  providedIn: 'root',
})
export class LibraryDocumentService {
  
  constructor(
    private readonly functions: FunctionsService,
    private readonly api: ApiService,
    @Inject(LIBRARY_SERVICE_TOKEN) private libraryService: LibraryService
  ) {}

  private documentSchemeMapper: { [key: string]: (edit?: boolean) => string } =
    {
      docx: (edit) => `ms-word:of${edit ? 'e' : 'v'}|u|`,
      doc: (edit) => `ms-word:of${edit ? 'e' : 'v'}|u|`,
      pptx: (edit) => `ms-powerpoint:of${edit ? 'e' : 'v'}|u|`,
      ppt: (edit) => `ms-powerpoint:of${edit ? 'e' : 'v'}|u|`,
      xlsx: (edit) => `ms-excel:of${edit ? 'e' : 'v'}|u|`,
      xls: (edit) => `ms-excel:of${edit ? 'e' : 'v'}|u|`,
      xlsm: (edit) => `ms-excel:of${edit ? 'e' : 'v'}|u|`,
      csv: (edit) => `ms-excel:of${edit ? 'e' : 'v'}|u|`,
    };

  private previewUrlProvider: {
    [key: string]: (
      sharepointId: string
    ) => Observable<{ data: string; type: PreviewType }>;
  } = {
    'doc;docx;docm;ppt;pptx;pptm;xlsx;xls;xlsm': (sharepointId) =>
      this.libraryService.getLibraryFileLocation(sharepointId).pipe(
        map((location) => {
          let relativeUrl = location.substring(
            location.indexOf('sharepoint.com/') + 14
          );
          if (relativeUrl.startsWith('//'))
            relativeUrl = relativeUrl.substring(1);
          return {
            data: `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
              this.functions.getSPValueUrl(relativeUrl, true) +
                `&state=${new Date().valueOf()}`
            )}`,
            type: PreviewType.Iframe,
          };
        })
      ),
    csv: (sharepointId) =>
      this.libraryService.getLibraryFileLocation(sharepointId).pipe(
        map((location) => {
          let relativeUrl = location.substring(
            location.indexOf('sharepoint.com/') + 14
          );
          if (relativeUrl.startsWith('//'))
            relativeUrl = relativeUrl.substring(1);
          return {
            data: `https://docs.google.com/gview?embedded=true&url=${encodeURIComponent(
              this.functions.getSPValueUrl(relativeUrl, true) +
                `&state=${new Date().valueOf()}`
            )}`,
            type: PreviewType.Iframe,
          };
        })
      ),
    pdf: (sharepointId) =>
      of({
        data: this.functions.getSPValueUrlById(sharepointId, true),
        type: PreviewType.Iframe,
      }),
    'png;jpeg;jpg;png;ico;gif;svg;apng;avif;jfif;pjpeg;pjp;webp;tiff': (
      sharepointId
    ) =>
      of({
        data: this.functions.getSPValueUrlById(sharepointId, true),
        type: PreviewType.Image,
      }),
    'mp4;3gp;ogg;ogv;wmv;webm;flv;avi;hdv;mov': (sharepointId) =>
      of({
        data: this.functions.getSPValueUrlById(sharepointId, true),
        type: PreviewType.Video,
      }),
    'mp3;wav': (sharepointId) =>
      of({
        data: this.functions.getSPValueUrlById(sharepointId, true),
        type: PreviewType.Audio,
      }),
    msg: (sharepointId) =>
      this.api.getBlob(this.functions.getSPValueUrlById(sharepointId)).pipe(
        switchMap((blob) => from(blob.arrayBuffer())),
        map((buffer) => {
          const msg = Msg.fromUint8Array(new Uint8Array(buffer));
          let msgBody: string = msg.getProperty(PidTagBody);
          msgBody = msgBody?.replaceAll(/(\r\n\s*)+/g, '\r\n');
          const msgSubject = msg.getProperty(PidTagSubject);
          return {
            data: `<h5>${msgSubject}</h5>
        <div
          class="min-height-0 w-100"
          style="white-space: pre-line"
        >
            ${msgBody}
        </div>`,
            type: PreviewType.Email,
          };
        }),
        catchError(() => of({ data: '', type: PreviewType.Error }))
      ),
    eml: (sharepointId) =>
      this.api.getBlob(this.functions.getSPValueUrlById(sharepointId)).pipe(
        switchMap((blob) => from(new PostalMime().parse(blob))),
        map((email) => {
          const attachmentBlobs = email.attachments
            .filter(
              (a) =>
                a.contentId &&
                'png;jpeg;jpg;png;ico;gif;svg;apng;avif;jfif;pjpeg;pjp;webp;tiff'
                  .split(';')
                  .includes(a.filename.split('.').pop()?.toLowerCase() ?? '')
            )
            .map((a) => ({
              id: a.contentId?.substring(1, a.contentId.length - 1),
              base64:
                typeof a.content == 'string'
                  ? a.content
                  : window.btoa(
                      new Uint8Array(a.content).reduce((data, byte) => {
                        return data + String.fromCharCode(byte);
                      }, '')
                    ),
              contentType:
                'image/' + a.filename.split('.').pop()?.toLowerCase(),
            }));
          let msgBody = email.html;
          attachmentBlobs.forEach(
            (a) =>
              (msgBody = msgBody?.replaceAll(
                `cid:${a.id}`,
                `data:${a.contentType};base64,${a.base64}`
              ))
          );
          const msgSubject = email.subject;
          return {
            data: `<h5>${msgSubject}</h5>
        <div
          class="min-height-0 w-100"
          style="white-space: pre-line"
        >
            ${msgBody}
        </div>`,
            type: PreviewType.Email,
          };
        }),
        catchError(() => of({ data: '', type: PreviewType.Error }))
      ),
  };

  hasOfficeScheme(extension: string) {
    return !!this.documentSchemeMapper[extension.toLowerCase()];
  }

  provideOfficeScheme(sharepointId: string, extension: string, edit = true) {
    if (!this.hasOfficeScheme(extension.toLowerCase())) return of('');
    const scheme = this.documentSchemeMapper[extension.toLowerCase()](edit);
    return this.libraryService
      .getLibraryFileDesktopAppUrl(sharepointId, edit)
      .pipe(map((location) => (location ? `${scheme}${location}` : '')));
  }

  providePreviewUrl(sharepointId: string, extension: string) {
    const providerCase = Object.keys(this.previewUrlProvider).find(
      (c) => c.toLowerCase().indexOf(extension.toLowerCase()) != -1
    );
    if (!providerCase) return of({ data: '', type: PreviewType.Error });
    return this.previewUrlProvider[providerCase](sharepointId);
  }
}
