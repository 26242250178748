import { CommonModule, registerLocaleData } from '@angular/common';
import localeNL from '@angular/common/locales/nl';
import {
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LuxonDateAdapter } from '@angular/material-luxon-adapter';
import {
  MatAutocomplete,
  MatAutocompleteModule,
  MatAutocompleteSelectedEvent,
} from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {
  MatChipEditedEvent,
  MatChipInputEvent,
  MatChipsModule,
} from '@angular/material/chips';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatRadioModule } from '@angular/material/radio';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { DateTime } from 'luxon';
import { Subject, combineLatest, first, map, startWith, takeUntil } from 'rxjs';
import {
  config$,
  translations$,
  users$,
} from 'src/app/core/data/data.observables';
import { MoveItemsDialogComponent } from '../move-items-dialog/move-items-dialog.component';
import {
  DatePickerFormatDirective,
  GroupUser,
  LIBRARY_SERVICE_TOKEN,
  LibraryIconService,
  LibraryItem,
  LOCALE_INJECTOR,
  MetadataFacade,
  MY_FORMATS,
  TranslationService,
} from 'processdelight-angular-components';
import { LibraryService } from 'src/app/core/services/library.service';
registerLocaleData(localeNL);

enum ShareLinkType {
  View,
  Edit,
}

@Component({
  selector: 'app-share-link-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatDialogModule,
    MatButtonModule,
    MatListModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatRadioModule,
    MatCheckboxModule,
    MatChipsModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    FormsModule,
    DatePickerFormatDirective,
  ],

  templateUrl: './share-link-dialog.component.html',
  styleUrls: ['./share-link-dialog.component.scss'],
})
export class ShareLinkDialogComponent implements OnInit, OnDestroy {
  item!: LibraryItem;

  ShareLinkType = ShareLinkType;

  shareLink = '';
  anonymousEnabled = true;

  typeControl = new FormControl<ShareLinkType>(ShareLinkType.View);
  expiryControl = new FormControl<DateTime | null>(null);

  expiryRequired = false;
  showExpiry = this.expiryRequired;

  destroy$ = new Subject<void>();

  today = new Date();

  users = users$.value;

  emailPeople: (GroupUser | string)[] = [];

  @ViewChild('autocomplete', { static: false })
  matAutocomplete!: MatAutocomplete;
  @ViewChild('personInput', { static: false })
  personInput!: ElementRef<HTMLInputElement>;

  fileNameMap: { [key: string]: string } = {};

  dateFormat = 'dd-MM-yyyy';

  constructor(
    private dialogRef: MatDialogRef<MoveItemsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: { item: LibraryItem },
    private readonly metadataFacade: MetadataFacade,
    @Inject(LIBRARY_SERVICE_TOKEN) private readonly libraryService: LibraryService,
    private readonly libraryIconService: LibraryIconService,
    private readonly domSanitizer: DomSanitizer,
    private readonly snackbar: MatSnackBar
  ) {}

  getTranslation$(label: string) {
    return translations$.pipe(map((t) => t[label]));
  }

  ngOnInit(): void {
    if (navigator.language.endsWith('US')) this.dateFormat = 'MM-dd-yyyy';
    this.item = this.data.item;
    this.expiryRequired =
      this.item.library &&
      config$.value.librarySharingOverrides[this.item.library.id]
        ? config$.value.librarySharingOverrides[this.item.library.id]
            .sharingExpirationRequired
        : config$.value.sharingExpirationRequired;
    this.showExpiry = this.expiryRequired;
    this.expiryControl.setValue(
      DateTime.utc().plus({
        days:
          this.item.library &&
          config$.value.librarySharingOverrides[this.item.library.id]
            ? config$.value.librarySharingOverrides[this.item.library.id]
                .sharingExpirationDefault
            : config$.value.sharingExpirationDefault,
      })
    );

    this.metadataFacade.fileNameParam$
      .pipe(first())
      .subscribe((fileNameParam) => {
        this.fileNameMap[this.item.id] =
          this.item.metadata.find(
            (m) => m.metadataParameter.id == fileNameParam?.id
          )?.value ?? '';
      });

    combineLatest([
      this.typeControl.valueChanges.pipe(startWith(ShareLinkType.View)),
      this.expiryControl.valueChanges.pipe(startWith(null)),
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.getNewShareUrl();
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getNewShareUrl() {
    this.shareLink = '';
    this.libraryService
      .getLibraryFileShareUrl(
        this.item.sharepointId,
        this.typeControl.value == ShareLinkType.Edit,
        this.showExpiry
          ? this.expiryControl.value?.setZone('utc').toJSDate() ?? undefined
          : undefined
      )
      .subscribe(({ shareLink, anonymousEnabled }) => {
        this.shareLink = shareLink;
        this.anonymousEnabled = anonymousEnabled;
      });
  }

  getIcon(item: LibraryItem) {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(
      this.libraryIconService.provideIcon(item.fileLocation)!
    );
  }

  getPersonName(person: GroupUser | string) {
    if (typeof person == 'string') {
      return person;
    } else {
      return person.displayName;
    }
  }

  addPerson(event: MatChipInputEvent) {
    if (!this.matAutocomplete.isOpen && this.anonymousEnabled) {
      const input = event.chipInput.inputElement;
      const value = event.value;
      const emailPattern = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;

      if ((value || '').trim() && emailPattern.test(value.trim())) {
        this.emailPeople.push(value.trim());
        if (input) {
          input.value = '';
        }
      }
    }
  }

  addGroupUser(event: MatAutocompleteSelectedEvent) {
    this.emailPeople.push(event.option.value);
    this.personInput.nativeElement.value = '';
  }

  editPerson(person: GroupUser | string, event: MatChipEditedEvent) {
    if (event.value) {
      this.emailPeople = this.emailPeople.map((p) =>
        p == person ? event.value : p
      );
    } else {
      this.removePerson(person);
    }
  }

  removePerson(person: GroupUser | string) {
    this.emailPeople = this.emailPeople.filter((p) => p != person);
  }

  close() {
    this.dialogRef.close();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  copyToClipboard() {
    if (this.shareLink) {
      navigator.clipboard
        .writeText(this.shareLink)
        .then(() =>
          this.snackbar.open('Copied to clipboard', 'Ok', {
            duration: 3000,
          })
        )
        .catch(() =>
          this.snackbar.open('Failed to copy to clipboard', 'Ok', {
            duration: 3000,
            panelClass: 'app-notification-error',
          })
        );
    }
  }

  openMail() {
    if (this.shareLink) {
      const toField = this.emailPeople
        .map((p) => (typeof p == 'string' ? p : p.user?.mail))
        .join(';')
      const subject = encodeURIComponent('Shared a document');
      const body = encodeURIComponent(this.shareLink);
      window.open(
        `mailto:${toField}?subject=${subject}&body=${body}`,
        '_blank'
      );
    }
  }
}
