import { HttpClient } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { EntityObject, ITasksService, Task } from "processdelight-angular-components";
import { catchError, map, Observable, throwError } from "rxjs";

@Injectable()
export class TasksService implements ITasksService {
    private readonly apiBase = `${location.origin}/web`;
    private readonly httpClient = inject(HttpClient);

    getTasks(
      pageSize: number,
      page: number,
      sortedColumn: string,
      sortDirection: string,
      internalSort: boolean,
      internalFilterString: string,
      dataFilterString: string
    ): Observable<{
      result: EntityObject<Task>[];
      pagingCookie: string;
      totalRecordCount: number;
    }> {
      return this.httpClient
        .get<{
          result: EntityObject<Task>[];
          pagingCookie: string;
          totalRecordCount: number;
        }>(
          `${
            this.apiBase
          }/TaskEntity?pageSize=${pageSize}&page=${page}${this.orderByQuery(
            internalSort,
            sortedColumn,
            sortDirection
          )}&internalFilter=${internalFilterString}&dataFilter=${dataFilterString}`
        )
        .pipe(
          map(({ result, pagingCookie, totalRecordCount }) => ({
            result: result.map(
              (c) =>
                new EntityObject<Task>({
                  ...c,
                  entity: new Task(c.entity ?? {}),
                })
            ),
            pagingCookie,
            totalRecordCount,
          }))
        );
  }

  orderByQuery(internalSort: boolean, column: string, direction: string) {
    if (!column || !direction) return '';
    else
      return (
        (internalSort ? '&internalOrderBy' : '&dataOrderBy') +
        `=${column}&orderByDirection=${direction.toUpperCase()}`
      );
  }

  public getTaskById(id: string): Observable<Task> {
    return this.httpClient.get<Task>(`${this.apiBase}/TaskEntity/${id}`)
      .pipe(map((task) => new Task(task)),
        catchError((error) => throwError(() => error)));
  }
}